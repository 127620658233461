const es = require('apexcharts/dist/locales/es.json')

export const opcionesGraficaDashboard = {
  chart: {
    fontFamily: 'Mulish, sans-serif',
    animations: {
      easing: 'linear',
      id: 'vuechart-historica',
    },
    height: '100%',
    background: 'transparent',
    defaultLocale: 'es',
    // dropShadow: {
    //   blur: 10,
    //   color: '#000',
    //   enabled: true,
    //   left: 7,
    //   opacity: 0.2,
    //   top: 18,
    // },
    locales: [es],
    toolbar: {
      show: true,
      export: {
      }
    },
    type: 'area', // bar, line, area,
  },
  // colors: ['#616166', '#BDBDBD', '#607D8B', '#FFF', '#263238'],
  dataLabels: {
    enabled: false,
  },
  legend: {
    horizontalAlign: 'center',
    position: 'top',
  },
  noData: {
    style: {
      color: 'white',
      fontSize: '2em',
    },
    text: 'No hay datos',
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  subtitle: {
    style: {
      color: '#9FA2B4',
      letterSpacing: '0.1px'
    },
    text: 'Subtitulo Provisional',
  },
  title: {
    style: {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#252733',
      letterSpacing: '0.4px'
    },
    text: 'Título Provisional',
  },
  tooltip: {
    style: {
      color: 'white',
    },
    // theme: 'dark',
    x: {
      format: 'dd MMM H:mm:ss',
      show: true,
    },
  },
  xaxis: {
    label: {
      datetimeUTC: false,
    },
    type: 'datetime',
  },
  yaxis: {
    opposite: true,
    labels: {
      style: {
        colors: ['#9FA2B4']
      }
    }
  },
}

export const opcionesDefaultGraficaRadial = {
  chart: {
    animations: {
      easing: 'linear',
      id: 'vuechart-actual',
    },
    background: 'transparent',
    defaultLocale: 'es',
    locales: [es],
    type: 'radialBar', // radialBar
    umed: [],
  },
  colors: ['#4CAF50'],
  fill: {
    colors: [({ value, seriesIndex, w }) => {
      return '#FF5252'
    }],
  },
  labels: [],
  legend: {
    horizontalAlign: 'center',
    position: 'top',
  },
  noData: {
    style: {
      color: 'black',
      fontSize: '2em',
    },
    text: 'No hay datos',
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: +90,
      dataLabels: {
        name: {
          color: 'black',
          fontSize: '0.75em',
          fontWeight: 'normal',
          offsetY: -15,
        },
        value: {
          color: 'black',
          fontSize: '1.5em',
          fontWeight: 'bold',
          offsetY: -10,
        },
        total: {
          show: false,
          label: 'Mayor',
          fontSize: '1.5em',
          fontWeight: 'bold',
          color: 'black',
        },
      },
    },
  },
  subtitle: {
    style: {
      color: 'black',
    },
    text: '',
  },
  title: {
    style: {
      color: 'black',
    },
    text: '',
  },
  toolbar: {
    show: true,
  },
  tooltip: {
    style: {
      color: 'black',
    },
    theme: 'dark',
  }
}

export const opcionesDefaultGraficaArea = {
  chart: {
    animations: {
      easing: 'linear',
      id: 'vuechart-historica',
    },
    background: 'transparent',
    defaultLocale: 'es',
    dropShadow: {
      blur: 10,
      color: '#000',
      enabled: true,
      left: 7,
      opacity: 0.2,
      top: 18,
    },
    locales: [es],
    toolbar: {
      export: {
      },
    },
    type: 'area', // bar, line, area,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    horizontalAlign: 'center',
    position: 'bottom',
  },
  noData: {
    style: {
      color: 'black',
      fontSize: '1.5em',
    },
    text: 'No hay datos',
  },
  stroke: {
    curve: 'smooth',
  },
  tooltip: {
    style: {
      color: 'black',
    },
    theme: 'dark',
    x: {
      format: 'dd MMM H:mm:ss',
      show: true,
    },
  },
  xaxis: {
    label: {
      datetimeUTC: false,
    },
    fontSize: '10px',
    type: 'datetime',
  },
}
