<template>
  <v-container>
    <v-row class="my-3" v-if="dispositivo">
      <v-col>
        <v-card
          class="d-flex flex-column justify-center align-center pa-5"
          height="135"
          min-width="120"
          outlined
          style="border-radius: 10px;"
        >
          <p class="card-title card-title--secondary mb-2">Ubicacion del dispositivo</p>
          <p class="card-subtitle card-subtitle--secondary mb-0">
            {{ dispositivo.ubicacion_linea_1 }} - {{ dispositivo.ubicacion_linea_2 }}
          </p>
        </v-card>
      </v-col>

      <v-col>
        <v-card
          class="d-flex flex-column justify-center align-center"
          height="135"
          min-width="120"
          outlined
          style="border-radius: 10px;"
        >
          <p class="card-title mb-2">Número sensores</p>
          <p class="mb-0 card-subtitle">{{ dispositivo.sensores.length }}</p>
        </v-card>
      </v-col>

      <v-col>
        <v-card
          class="d-flex flex-column justify-center align-center danger-color"
          height="135"
          min-width="120"
          outlined
          style="border-radius: 10px;"
        >
          <p class="card-title card-title--danger mb-2">Alertas</p>
          <p class="card-subtitle card-subtitle--danger mb-0">{{ estadisticas.cantidad_alarmas_dia || 0 }}</p>
        </v-card>
      </v-col>

      <v-col>
        <v-card
          class="d-flex flex-column justify-center align-center"
          height="135"
          min-width="120"
          outlined
          style="border-radius: 10px;"
        >
          <p class="card-title mb-2">Alertas Mes</p>
          <p class="mb-0 card-subtitle">
            {{ estadisticas.cantidad_alarmas_mes || 0 }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          outlined
          style="border-radius: 10px;"
        >
          <v-row>
            <v-col cols="12" sm="8">
              <apexchart
                class="pa-sm-5"
                :options="grafica.options"
                :series="grafica.series"
                ref="grafica"
              />

            </v-col>
            <v-col class="pl-sm-0" cols="12" sm="4">
              <v-list class="py-0 d-flex flex-column fill-height" style="border-top-right-radius: 10px; border-bottom-right-radius: 10px; border-left: 1px solid rgba(0, 0, 0, 0.12);">
                <template>
                  <v-list-item class="text-center my-list-item">
                    <template>
                      <v-list-item-content>
                        <p class="my-list-item-title">Máximo</p>
                        <p class="my-list-item-subtitle" v-if="sensorPrincipal">
                          {{ sensorPrincipal.registro_maximo }} {{ sensorPrincipal.unidad_medida.simbolo }}
                        </p>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>

                <template>
                  <v-list-item class="text-center my-list-item">
                    <template>
                      <v-list-item-content>
                        <p class="my-list-item-title">Mínimo</p>
                        <p class="my-list-item-subtitle" v-if="sensorPrincipal">
                          {{ sensorPrincipal.registro_minimo }} {{ sensorPrincipal.unidad_medida.simbolo }}
                        </p>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>

                <template>
                  <v-list-item class="text-center my-list-item">
                    <template>
                      <v-list-item-content>
                        <p class="my-list-item-title">Media</p>
                        <p class="my-list-item-subtitle" v-if="sensorPrincipal">
                          {{ sensorPrincipal.registro_promedio }} {{ sensorPrincipal.unidad_medida.simbolo }}
                        </p>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>

                <template>
                  <v-list-item class="text-center my-list-item">
                    <template>
                      <v-list-item-content>
                        <p class="my-list-item-title">Porcentaje para medio</p>
                        <p class="my-list-item-subtitle" v-if="sensorPrincipal">
                          {{ Math.round(sensorPrincipal.porcentaje_medio * 100) - 100 }}%
                        </p>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>

                <template>
                  <v-list-item class="text-center my-list-item">
                    <template>
                      <v-list-item-content>
                        <p class="my-list-item-title">Porcentaje para alto</p>
                        <p class="my-list-item-subtitle" v-if="sensorPrincipal">
                          {{ Math.round(sensorPrincipal.porcentaje_alto * 100) - 100 }}%
                        </p>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>

              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { opcionesGraficaDashboard } from '@/utils/charts'
import axios from '@/plugins/axios'
import moment from 'moment'

export default {
  name: 'Resumen',
  props: ['codigo'],
  data () {
    return {
      grafica: {
        series: [
          {
            data: [],
            name: 'Sensor 1'
          }
        ],
        options: opcionesGraficaDashboard
      },
      dispositivo: null,
      sensorPrincipal: null,
      estadisticas: {}
    }
  },
  methods: {
    async fetchDispositivoByCodigo (dispositivoCodigo) {
      const dispositivoResponse = await axios.get(`api/iot/dispositivos/${dispositivoCodigo}/`)
      return dispositivoResponse.data
    },
    async fetchRegistrosSensor (dispositivoCodigo, sensorCodigo) {
      const fecha = moment().format('YYYY-MM-DD')
      return axios.get(`api/iot/registros_historicos/for_apex/?sensor__codigo=${sensorCodigo}&sensor__dispositivo__codigo=${dispositivoCodigo}&fecha_real__date=${fecha}`)
    },
    async fetchDatosGrafica () {
      const regsistrosResponse = await this.fetchRegistrosSensor(this.codigo, this.sensorPrincipal.codigo)
      this.grafica.series = [{
        name: this.sensorPrincipal.nombre,
        data: regsistrosResponse.data,
      }]
      this.grafica.options.chart.toolbar.export.csv = {
        dateFormatter (timestamp) {
          return moment(timestamp).add(5, 'h').format('DD/MM/YYYY HH:mm:ss')
        },
        filename: `${this.sensorPrincipal.codigo}@${this.codigo}`,
        headerCategory: 'Fecha',
        headerValue: 'Registro',
      }
      this.grafica.options.subtitle.text = this.sensorPrincipal.descripcion
      this.grafica.options.title.text = `${this.sensorPrincipal.nombre}(${this.sensorPrincipal.unidad_medida.simbolo})`

      this.$refs.grafica.updateSeries(this.grafica.series)
      this.$refs.grafica.updateOptions(this.grafica.options)
    },
    async fetchEstadisticasDispositivo (codigo) {
      const estadisticasRepsonsive  =  await axios.get(`api/iot/dispositivos/${codigo}/estadisticas/`)
      return estadisticasRepsonsive.data
    }
  },
  async mounted () {
    this.dispositivo = await this.fetchDispositivoByCodigo(this.codigo)
    this.estadisticas = await this.fetchEstadisticasDispositivo(this.codigo)
    this.estadisticasDispositivos = await this.fetchEstadisticasDispositivo(this.codigo)
    this.sensorPrincipal = this.dispositivo.sensores.find((sensor) => sensor.principal)
    this.fetchDatosGrafica()
  }
}
</script>

<style scoped lang="scss">
.danger-color {
  color: #EB5757;
  border-color: #EB5757;
}

.card-title {
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #9FA2B4;

  &--secondary {
    font-size: 14px;
    line-height: 18px;
  }

  &--danger {
    color: #EB5757;
  }
}

.card-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 1px;
  color: #252733;

  &--secondary {
    font-size: 24px;
    line-height: 30px;
  }

  &--danger {
    color: #EB5757;
  }
}

.my-list-item {
  height: 5.93rem;
}

.my-list-item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #9FA2B4;
  margin-bottom: 6px;
}

.my-list-item-subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #252733;
  margin-bottom: 0;
}

.apexchart-container {
  height: 529px;
}

.col-derecha-grafica {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}


@media (max-width: 599px) {
  .apexchart-container {
    height: 360px;
  }

  .col-derecha-grafica {
    margin-top: -60px;
  }

}

</style>
